/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'cairo';
  src: url('assets/fonts/Cairo/Cairo-Regular.ttf');
}
@font-face {
  font-family: 'AnotherFont';
  src: url("assets/fonts/Tajawal/Tajawal-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'Montserrat';
  src: url("assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
body{
  font-family: Montserrat;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: Montserrat;
}
.btn {
  @apply py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-400 m-6
}

/* Success toast background color */
.toast-success {
  background-color: #00cc66;
}

/* Error toast background color */
.toast-error {
  background-color: #ff3333;
}

/* Info toast background color */
.toast-info {
  background-color: #3399ff;
}

/* Warning toast background color */
.toast-warn {
  background-color: #ffcc00;
}

