
/*https://products.fileformat.app/compress/svg */
@font-face {
    font-family: 'Beau Rivage';
    src: url("../fonts/BeauRivage-Regular.ttf");

}
@font-face {
    font-family: 'Poppins';
    src: url("../fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
body{
    background-color: #080B2A;
}
.general{
    background: linear-gradient(to right, #1C2350, #080C2C 70%, #080C2C 90%, #080C2C);
}
@media (max-width: 765px) {
    .applydemo {
      margin-bottom: 15px;
    }

  }
p{
    font-size: 16px !important;
    font-family: Poppins !important;
}
/*section 1*/
#Home-section {
    background: linear-gradient(to bottom, #080C2C 0%, #080C2C 100%, #080C2C 100%);
    /*ackdrop-filter: blur(10px);*/
    min-height: 100vh;
    position: relative;
}

.spotlight-section {
    position: relative;
    background: linear-gradient(to bottom, #486687 0%, #233853 100%, #233853 100%);
    overflow: hidden; 
}

.spotlight-section::before,
.spotlight-section::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center top, rgba(255, 255, 255, 0.226), transparent);
   
}
.spotlight-section::before {
    left: 0;
    transform: translateX(-50%);
}

.spotlight-section::after {
    right: 0;
    transform: translateX(50%);
}

.section1header{
    margin: 5rem auto 0px auto;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 90px; /* 187.5% */
}
.section1subtitle{
    color: rgba(236, 236, 236, 0.65);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Beau Rivage;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 102.4px */
}
.star1 {
    top: 6rem;
    right: 20rem;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: cover;
    animation: blink 4s infinite;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
}
.star2 {
    top: 5rem;
    right: 43rem;
    position: absolute;
    width: 15px;
    height: 15px;
    opacity: 0.30;
    background-size: cover;
    animation: blink 4s infinite;
    background-image: url('../frontend/images/star-9.svg');
}
.star3 {
    top: 6rem;
    right: 63rem;
    position: absolute;
    width: 15px;
    height: 15px;
    opacity: 0.30;
    background-size: cover;
    animation: blink 4s infinite;
    background-image: url('../frontend/images/star-9.svg');
}
.star4 {
    top: 45rem;
    right: 45rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.30;
    animation: blink 4s infinite;
    background-image: url('../frontend/images/star-9.svg');
}
.star5 {
    top: 30rem;
    left: 2rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.30;
    animation: blink 4s infinite;
    background-image: url('../frontend/images/star-9.svg');
}
@keyframes blink {
    0%, 50%, 100% {
        opacity: 0.10; /* Change the opacity to the desired value */
    }
    25%, 75% {
        opacity: 0.30; /* Change the opacity to the desired value */
    }
}

/*section 2*/
.section2 {
    background-size: cover;
    background-image: url('../frontend/images/section2transparent.svg');
}
.section2headtxt{
    color: #FFF;
    text-align: left;
    font-family: Poppins;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 133.333% */
    margin-bottom: 35px;
}
.section2subtitle{
    color: #FFF;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: lighter;
    line-height: 30px;
}
.section2subtxt{
    color: #C4EEFF;
    text-align: center;
    font-family: Beau Rivage;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px; /* 166.667% */
    letter-spacing: 3.78px;
    text-transform: capitalize;
}
.sestar1 {
    bottom: 43.7rem;
    right: 12rem;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: cover;
    animation: blink 4s infinite;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    /*filter: blur(2px);*/
}
.sestar3 {
    right: 6rem;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: cover;
    animation: blink 4s infinite;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    bottom: 40rem;
    /*filter: blur(2px);*/
}
.sestar2 {
    bottom: 20rem;
    left: 50rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    animation: blink 4s infinite;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    /*filter: blur(2px);*/
}
.sestar4 {
    bottom: 34rem;
    left: 45rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    /*ilter: blur(4px);*/
}
/*section3*/
.hoor {
    position: absolute;
    left: -6rem;
    top: -5rem;
    opacity: 0.3;
}
.s3estar1 {
    left: 28rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.4;
    background-image: url('../frontend/images/star-9.svg');
    bottom: 33rem;
}
.s3estar2 {
    right: 19.6rem;
    position: absolute;
    width: 25px;
    height: 25px;
    background-size: cover;
    opacity: 0.2;
    background-image: url('../frontend/images/star-9.svg');
    bottom: 6rem;
}
.s3estar3 {
    right: 48rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.4;
    background-image: url('../frontend/images/star-9.svg');
    bottom: -6rem;
}
.s3image-container {
    top: -8rem;
    left: -7rem;
    width: 100%;
    height: 850px;
    background-image: url('../frontend/images/animation/Octagon1.svg');
    background-position: center;
    background-size: cover;
    opacity: 0.1;
}

/* Adjust the z-index for the cards' content */
.card-content {
    position: relative;
    z-index: 2;
}
.sect3card1l1, .sect3card2l2, .sect3card3l3 { 
    position: absolute;
    background-image: url('../frontend/images/cardmainbg.svg');
    background-repeat: no-repeat;

}
/* Additional background classes for cards */
.cardbgl1, .cardbgl2, .cardbgl3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 533px;;
    background-image: url('../frontend/images/cardbg.svg');
    background-size: cover;
    background-position: center;
    z-index: 1;
}

/*section4*/
.s4estar1 {
    right: 25rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    top: 2rem;
    /*filter: blur(1px);*/
}
.s4estar2 {
    right: 20rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    top: 5rem;
    /*filter: blur(1px);*/
}
.timeline {
    position: relative;
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 15px 0;
}
.timeline::after {
    content: '';
    position: absolute;
    width: 4px;
    background: #8DD7EF;
    top: 9rem;
    bottom: 10rem;
    left: 50%;
    margin-left: -1px;
    box-shadow: -10px 0px 10px rgb(18 16 16 / 27%);
}
.container {
    padding: 15px 6px;
    position: relative;
    background: inherit;
    width: 46%;
}

/* left background */
.container.left {
    position: relative; /* Needed for positioning the absolute layers */
    left: 0;
}

.background-layer1 {
    position: absolute;
    top: 0px;
    left: 20.5rem;
    width: 100%;
    height: 100%;
    background-image: url('../frontend/images/leftsequare.svg');
    background-repeat: no-repeat;
    z-index: -1; /* Place the layer behind other content */
}

.background-layer2 {
    position: relative; /* Keep this relative so the content is positioned properly */
    background-image: url('../frontend/images/Rectangleleft.svg');
    background-repeat: no-repeat;
    right: 20px;
}
/* left background */

/* right background */
.container.right {
    position: relative; /* Needed for positioning the absolute layers */
    left: 0;
}
.background-rlayer1 {
    position: absolute;
    top: 0px;
    right: 1.7rem;
    width: 100%;
    height: 100%;
    background-image: url('../frontend/images/rightsequare.svg');
    background-repeat: no-repeat;
    z-index: -1;
}
.background-rlayer2 {
    position: relative; /* Keep this relative so the content is positioned properly */
    background-image: url('../frontend/images/Rectangleright.svg');
    background-repeat: no-repeat;
    right: 20px;
}
/* left background */
.container.right {
    left: 58%;
}
.container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    top: calc(50% - 10px);
    right: -3.7rem;
    background: #8DD7EF;
    border: 2px solid #8DD7EF;
    border-radius: 16px;
    z-index: 1;
}
.container.right::after {
    left: -6.4rem;
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    top: calc(50% - 25px);
    background: #8DD7EF;
    border: 2px solid #8DD7EF;
    border-radius: 16px;
    z-index: 1;
}
.container::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 8px;
    top: calc(50% - 1px);
    right: -2.2rem;
    background-image: url('../frontend/images/lefrarrow.svg');
    z-index: 1;
}
.container.right::before {
    left: -5rem;
    content: '';
    position: absolute;
    width: 50px;
    height: 8px;
    top: calc(50% - 13px);
    right: -2.2rem;
    background-image: url('../frontend/images/rightarrow.svg');
    z-index: 1;
}
.container.left .date {
    right: -75px;
}

.container.right .date {
    left: -75px;
}

.container.left .icon {
    right: 56px;
}

.container.right .icon {
    left: 56px;
}

.container .content {
    padding: 33px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05); 
}
.container.right .content {
    border-radius: 8px;
}
.container .content h2 {
    margin: 0 0 10px 0;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 125% */
    text-align: center;
}

.container .content p {
    margin: 0;
    color: #B4C5CB;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    border-image: linear-gradient(to bottom, #8DD7EF, transparent);
}
/*section5*/
.elipsbanner{
    top: -5rem;
    left: 5rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 356px;
    background: #9FDCF4;
    filter: blur(175px);
}
.elipsbanner2{
    top: -5rem;
    right: 5rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 356px;
    background: #76B6C7;
    filter: blur(175px);
}
.elipsbenefits{
    top: 0;
    left: 5rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 356px;
    background: #E3E3E3;
    filter: blur(175px);
    background-size: cover;
}
.elipsbenefits2{
    top: 15rem;
    right: 8rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 356px;
    background: #376780;
    filter: blur(175px);
}
.elipscontactus{
    bottom: 30rem;
    left: -5rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 408px;
    background: #2A3E84;
    filter: blur(175px);
}
.elipscontactus2{
    bottom: 15rem;
    right: -8rem;
    position: absolute;
    width: 356px;
    height: 356px;
    border-radius: 356px;
    background: #508F9A;
    filter: blur(175px);
}
.s5estar1{
    top: 34rem;
    right: 10rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
   /* filter: blur(1px);*/
}
.s5estar2{
    top: 0rem;
    right: 45rem;
    position: absolute;
    width: 10px;
    height: 10px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    /*filter: blur(1px);*/
}
.s5estar3{
    top: -5rem;
    left: 10rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
    /*filter: blur(1px);*/
}
.s6estar1{
    bottom: -1rem;
    left: 10rem;
    position: absolute;
    width: 15px;
    height: 15px;
    background-size: cover;
    opacity: 0.5;
    background-image: url('../frontend/images/star-9.svg');
   /* filter: blur(1px);*/
}
.s7estar1{
    bottom: 75rem;
    right: 45rem;
    position: absolute;
    width: 20px;
    height: 20px;
    background-size: cover;
    opacity: 0.3;
    background-image: url('../frontend/images/star-9.svg');
}

/* toast-custom.css */

/* Override the toast container styles */
.Toastify__toast-container {
    /* Your custom styles here */
}

/* Override the toast styles */
.Toastify__toast {
    /* Your custom styles here */
}

/* Override the toast success styles */
.Toastify__toast--success {
    /* Your custom styles here */
}

/* Override the toast error styles */
.Toastify__toast--error {
 
}
/* media queries */

@media (max-width: 767.98px) {
    .timeline::after {
        display: none;
    }
    .container::after{
        display: none;
    }
    .container::before{
        display: none;
    }
    .container {
        width: 100%;
        padding: 14px 14px;
    }
    .container.right {
        left: 0%;
    }
    .container.left::after,
    .container.right::after {
        left: 82px;
    }
    .container.left::before,
    .container.right::before {
        left: 100px;
    }
    .container.left .date,
    .container.right .date {
        right: auto;
        left: 15px;
    }
    .container.left .icon,
    .container.right .icon {
        right: auto;
        left: 146px;
    }
    .background-rlayer1 {
        display: none;
    }
    .background-layer1{
        display: none;
    }
    .background-layer2{
        background-image: none;
        right: 0px;
    }
    .background-rlayer2{
        background-image: none;
        right: 0px;
    }
    .section2headtxt {
        text-align: center;
    }
    .s3estar1{
        display: none;
    }
    .s3estar2{
        display: none;
    }
    .s3estar3{
        display: none;
    }
    .sestar2{
        display: none;
    }
    .sestar4{
        display: none;
    }
    .hoor{
        display: none;
    }
    .card1{
        margin-bottom: 2rem;
    }
    .elipsbenefits{
        display: none;
    }
    .elipsbenefits2{
        display: none;
    }
    .elipscontactus{
        display: none;
    }
    .elipscontactus2{
        display: none;
    }
    .elipsbanner{
        display: none;
    }
    .elipsbanner2{
        display: none;
    }
    /*popup*/
    .popupdesgin {
        border-radius: 0px;
        margin-top: 0px;
        padding: 0px 15px 5px 45px;
    }
    .top-2 {
        top: -1rem;
    }
    .right-2 {
        right: -0.5rem;
    }
    .maintxt {
        font-size: 20px;
        position: relative;
        top: -50px;
    }
    .subtxt {
        font-size: 10px !important;
        margin-top: -60px;
    }
    .btnclose{
        width: 1.5rem;
    }
    .lblupload {
        font-size: 10px !important;
    }
    .Inerlblupload {
        font-size: 10px !important;  
    }
    .uploadarea {
        padding: 20px 16px;
    }
    .txtarea{
        height: 75px;
    }
    .terms{
       font-size: 10px;
    }
    
}
