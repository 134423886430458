/**alaa**/
body{
  overflow-x: hidden;
}
.homesection{
  background: linear-gradient(180deg, #FBD1BB 0%, #FBD1BB 50% , #5888BE 100%);
  backdrop-filter: blur(10px);
  min-height: 100vh;
}
.ptext{
  color: #ffffff91;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.18px;
}
.gradient-text {
  background: linear-gradient(179deg, #ECECEC 0%, rgba(236, 236, 236, 0.00) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sectiontext{
  color: rgba(236, 236, 236, 0.65);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}
.datadriven{
  color: rgba(236, 236, 236, 0.65);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Cairo;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.btnapply{
  border-radius: 60px;
  background: #ECECEC;
}
.btncontact{
  border-radius: 60px;
  border: 1px solid #ECECEC;
}
.mainsection{
  position: relative;
}
.mainsection::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../frontend/images/abstract-background.png');
  background-size: cover;
  background-position: center;
  opacity: 0.2;
  z-index: -1;
}
.Section1{
  position: relative;
}
.Section1::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../frontend/images/section1bg.svg');
  background-size: cover;
  background-position: center;
  opacity: 0.1; 
  z-index: -1; 
}
.bluecard{
  box-shadow: 0 4px 15px 7px rgb(243 241 241 / 10%);
  border-radius: 20px;
}
.boxshadow{
  box-shadow: 0 0 10px 1px #f3f3f3d1;
}
.column{
  border-radius: 10px;
  background: rgba(37, 81, 132, 0.20);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 273px;
  height: 153px;
  flex-shrink: 0;
  padding: 20px;
}
.columnh{
  color: #FFF;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: 157.4%; /* 34.628px */
  padding-bottom: 10px;
}
.columnp{
  color: #F0F0F0;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 157.4%; /* 34.628px */
}
.my-box1:hover {
  border: 4px solid #2d7ed6;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 0 rgba(0, 0, 0, 0); /* Add 0 offset and 0 blur for the second shadow */
}
.my-box2:hover {
  border: 4px solid #cc7400;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 0 rgba(0, 0, 0, 0); /* Add 0 offset and 0 blur for the second shadow */
}
.my-box3:hover {
  border: 4px solid #760dd6;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 0 rgba(0, 0, 0, 0); /* Add 0 offset and 0 blur for the second shadow */
}
.my-box4:hover {
  border: 4px solid #46d300;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5), 0 0 0 rgba(0, 0, 0, 0); /* Add 0 offset and 0 blur for the second shadow */
}
.dashboardimg{
  max-width: 65%;
  margin-right: 40px;
  margin-top: 5px;
}
/* Custom style for input and textarea placeholders */
input, textarea, input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  font-size: 12px;
}
.inp {
  border:none;
  border-bottom: 1px solid #8D8D8D;
  padding: 5px 10px;
  outline: none;
}

[placeholder]:focus::-webkit-input-placeholder 
{
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}

[type='text']:focus, 
input:where(:not([type])):focus, 
[type='email']:focus, [type='url']:focus,
[type='password']:focus, [type='number']:focus,
[type='date']:focus, 
[type='datetime-local']:focus, 
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus, 
[type='time']:focus,
[type='week']:focus,
[multiple]:focus, 
textarea:focus, select:focus
{
  outline: none !important;
}
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='week']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-color: transparent;
  border-bottom-width: 2px;
  border-bottom-color: gray;
  outline: none;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #ffffff;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) #ffffff;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

/*desktop*/
.desktopfooter{
  background-color: #26528E;
}
.desktopsection1{
  background-image: url('../frontend/desktop/section1.svg');
  background-size: cover;
  background-position: center;
}
.Section1maintxt{
  /* text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25); */
  font-family: Montserrat;
  font-size: 128px;
  font-style: normal;
  font-weight: 700;
  line-height: 137.5px; /* 107.422% */
  background: linear-gradient(132deg, #EE611B 0.30%, #045098 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Section1subtext{
  color: #045098;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px; /* 170% */
  letter-spacing: 3px;
}
.Section1btn{
  width: 220px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(218deg, #8DD7EF 0%, #3F7286 50.52%, #2B3074 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.scetion2card{
  border-radius: 67px;
background: rgba(255, 255, 255, 0.69);
}
.cardtitle{
  color: #1D2130;
  font-family: Roboto;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}
.cardsubtitle{
  color:  #1D2130;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}
.section3card{
  border-radius: 24px;
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.31) 100%);
}
.section4subtitle{
  color: #045098;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
}
.section4title{
  color:  #1D2130;
  font-family: Roboto;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
.section5title{
  color: #1D2130;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 57.6px */
}
/*animation*/
/*1*/
@keyframes wheelAnimation {
  0% {
    transform: rotate(0deg); /* Start with no rotation */
  }
  100% {
    transform: rotate(360deg); /* End with a full rotation */
  }
}

.animate-wheel {
  animation-name: wheelAnimation;
  animation-timing-function: linear; /* Linear timing for constant speed */
  animation-duration: 2s; /* Adjust this value to control the speed of rotation */
  animation-iteration-count: infinite; /* Loop the animation infinitely */
}
/*2*/
@keyframes OctagonAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotation {
  animation-name: OctagonAnimation;
  animation-timing-function: linear;
  animation-duration: 10s; /* Adjust this value to control the rotation speed */
  animation-iteration-count: infinite;
}
.relative {
  position: relative;
  z-index: 0; /* Ensure the parent container has a higher z-index than the image container */
}

.image-container {
  position: absolute;
  margin-top: -300px;
  margin-left: -10rem;
}
.bluecard {
  /* Your existing styles for the bluecard */
  position: relative;
  z-index: 1; /* Ensure the bluecard is on top of the image container */
}
/*3*/
/* ImageAnimation.css */
.image-animation {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 100%;
  animation-name: fadeInOut;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  width:18%;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.image-animation2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 100%;
  animation-name: fadeInOut;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  width:18%;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/* Popup.css (or use inline styles) */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the popup is above other content */
}
.popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 67px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}
/*popup*/
.maintxt{
  color: #000;
  text-align: center;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 52.174% */
  letter-spacing: 0.46px;
}
.submaintxt{
  color: #045098;
}
.subtxt{
  color: #2C296B;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.14px;
}
.btnsubmit{
  border-radius: 10px;
  background: linear-gradient(218deg, #8DD7EF 0%, #3F7286 50.52%, #2B3074 100%);
  display: flex;
  width: 549px;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.popupdesgin{
  border-radius: 50px;
  margin-top: 70px;
}
.uploadarea{
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px dashed #8E8E8E;
}
.Inerlblupload{
  color: #8E8E8E;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 20.8px */
  letter-spacing: 0.5px;
}
.lblupload{
  color: #8E8E8E;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 20.8px */
  letter-spacing: 0.5px;
}
/* media query */

@media (max-width: 768px) {
  .btnmore {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; /* Ensures the container takes full height for centering */
      position: relative; /* Reset positioning */
      bottom: 0;
      right: 0;
      -mr-3: 0;
      transform: none; /* Reset transformation */
  }
  .inline-flex {
    margin-right: 40%;
  }
  .image-container-Section3{
    display: none !important;
  }
  .image-container{
    display: none !important;
  }
  .bluecard{
   margin: 0px !important;
  }
  .scetion2card{
    padding: 3rem;
  }
  .section3card{
    padding: 15px;
  }
  .Section1maintxt {
    font-size: 59px;
    line-height: 90.5px;
}
}


